import { createEvent, createStore } from "effector";
import ZHKINFO__TitleImage from "../ProjectsLists/ZHKINFO/ZHKINFO.png";
import ZHKINFO__Image1 from "../ProjectsLists/ZHKINFO/Image1.png";
import ZHKINFO__Image2 from "../ProjectsLists/ZHKINFO/Image2.png";

import PROEfficient__TitleImage from "../ProjectsLists/PROEfficient/PROEfficient.png";
import PROEfficient__Image1 from "../ProjectsLists/PROEfficient/PROEfficient__Image1.png";

import Platezhka__TitleImage from "../ProjectsLists/Platezhka/Platezhka.png";
import Platezhka__Image1 from "../ProjectsLists/Platezhka/Platezhka__Image1.png";

import LionInvest__TitleImage from "../ProjectsLists/LionInvest/LionInvest.png";
import LionInvest__Image1 from "../ProjectsLists/LionInvest/LionInvest__Image1.png";
import LionInvest__Image2 from "../ProjectsLists/LionInvest/LionInvest__Image2.png";

import DSoft__TitleImage from "../ProjectsLists/DSoft/DSoft.png";
import DSoft__Image1 from "../ProjectsLists/DSoft/DSoft__Image1.png";

import ZHKINFO__TitleImageWeb from "../ProjectsLists/ZHKINFO/ZHKINFO.webp";
import ZHKINFO__Image1Web from "../ProjectsLists/ZHKINFO/Image1.webp";
import ZHKINFO__Image2Web from "../ProjectsLists/ZHKINFO/Image2.webp";

import PROEfficient__TitleImageWeb from "../ProjectsLists/PROEfficient/PROEfficient.webp";
import PROEfficient__Image1Web from "../ProjectsLists/PROEfficient/PROEfficient__Image1.webp";

import Platezhka__TitleImageWeb from "../ProjectsLists/Platezhka/Platezhka.webp";
import Platezhka__Image1Web from "../ProjectsLists/Platezhka/Platezhka__Image1.webp";

import LionInvest__TitleImageWeb from "../ProjectsLists/LionInvest/LionInvest.webp";
import LionInvest__Image1Web from "../ProjectsLists/LionInvest/LionInvest__Image1.webp";
import LionInvest__Image2Web from "../ProjectsLists/LionInvest/LionInvest__Image2.webp";

import DSoft__TitleImageWeb from "../ProjectsLists/DSoft/DSoft.webp";
import DSoft__Image1Web from "../ProjectsLists/DSoft/DSoft__Image1.webp";

import Swift from "../assets/Technologies/Swift.png";
import Kotlin from "../assets/Technologies/Kotlin.png";
import GoLang from "../assets/Technologies/GoLang.png";
import React from "../assets/Technologies/React.png";
import CSharp from "../assets/Technologies/CSharp.png";

import SwiftWeb from "../assets/Technologies/Swift.webp";
import KotlinWeb from "../assets/Technologies/Kotlin.webp";
import GoLangWeb from "../assets/Technologies/GoLang.webp";
import ReactWeb from "../assets/Technologies/React.webp";
import CSharpWeb from "../assets/Technologies/CSharp.webp";

export const $ProjectsLists = createStore<any>([
  {
    url: "ZHKINFO",
    short: {
      description: "Продукт “ЖКХ.Инфо”",
      image: ZHKINFO__TitleImage,
      imageWeb: ZHKINFO__TitleImageWeb,
    },
    long: {
      title: "Продукт «ЖКХ.Инфо»",
      image: ZHKINFO__Image1,
      imageWeb: ZHKINFO__Image1Web,
      about:
        "Данный продукт - результат нескольких лет работы нашей команды разработчиков. Продукт из разряда B2B2C, предназначенный для управляющих компаний (УК) и их жителей. Основное назначение: автоматизировать взаимодействие между жителей и УК.",
      task: `В стране существует огромное множество управляющих компаний, которые стремятся к цифровизации своих услуг. После длительного анализа рынка наша команда приняла решение помочь им в этом. \n
            Необходимо было с нуля создать удобный сервис как для управляющих компаний, так и для их жителей. В основе сервиса должны были лежать мобильное приложение для оплаты ЖКХ услуг, личный кабинет жителя и CRM-система для управления лицевыми счетами.`,
      technologies: [
        {
          title: "Swift",
          image: Swift,
          imageWeb: SwiftWeb,
        },
        {
          title: "Kotlin",
          image: Kotlin,
          imageWeb: KotlinWeb,
        },
        {
          title: "GoLang",
          image: GoLang,
          imageWeb: GoLangWeb,
        },
        {
          title: "React",
          image: React,
          imageWeb: ReactWeb,
        },
      ],
      result:
        "Мы создали удобный сервис и продолжаем его развивать. На данный момент сервис включает мобильное приложение для оплаты ЖКХ услуг, просмотра начислений и новостей, а также передачи показаный счетчикой; личный кабинет жителя; CRM-систему для управления лицевыми счетами. \n\nЗа время существования продукта к нам присоединилось 10 управляющих компаний и более 58 тысяч пользователей мобильного приложения по всей России.",
      app: {
        image: ZHKINFO__Image2,
        imageWeb: ZHKINFO__Image2Web,
        appStore:
          "https://apps.apple.com/ru/app/%D0%B6%D0%BA%D1%85-%D0%B8%D0%BD%D1%84%D0%BE/id1485202261",
        playMarket:
          "https://play.google.com/store/apps/details?id=ru.krasabs.izba",
        appGallery: "https://appgallery.huawei.ru/app/C106382273",
        RuStore: "https://apps.rustore.ru/app/ru.krasabs.izba",
      },
    },
  },
  {
    url: "PROEfficient",
    short: {
      description: "Модуль системы PRO-Efficient",
      image: PROEfficient__TitleImage,
      imageWeb: PROEfficient__TitleImageWeb,
    },
    long: {
      title: "Модуль системы PRO-Efficient",
      image: PROEfficient__Image1,
      imageWeb: PROEfficient__Image1Web,
      about:
        "Модуль системы PRO-Efficient под названием PROmaintenance для учета планирования и учета выполненных работ по техническому обслуживанию и ремонту.",
      client:
        "BDE Engineering – немецкая компания, предоставляющая MES-системы для повышения эффективности производства в области сбора производственных данных и визуализации производства в области планирования, мониторинга, энергетики, профилактического обслуживания и ключевых показателей.",
      task: `Система оперативного контроля и регистрации производственных данных PRO-Efficient обязательно нуждается в модуле для планово-предупредительных ремонт и техобслуживания. \n Необходимо было сделать консольное приложение для ведения учета с применением 3-х стратегий ТО: по времени, по наработке, по экспертной оценке.`,
      technologies: [
        {
          title: "C#",
          image: CSharp,
          imageWeb: CSharpWeb,
        },
      ],
      result:
        "Модуль PROmaintenance обеспечивает ясность в обслуживании и ремонте для определенных объектов технического обслуживания. Для каждого вида работ по ТО можно определять периодичность, систему оповещения, показатели данных наработки для любого каждого технического объекта.",

      app: {
        image: "",
        appStore: "",
        playMarket: "",
        appGallery: "",
        RuStore: "",
      },
    },
  },
  {
    url: "Platezhka",
    short: {
      description: "Мобильное приложение “Платежка”",
      image: Platezhka__TitleImage,
      imageWeb: Platezhka__TitleImageWeb,
    },
    long: {
      title: "Приложение «Платежка»",
      image: Platezhka__Image1,
      imageWeb: Platezhka__Image1Web,
      about: "Мобильное приложение для оплаты услуг связи, ЖКХ и прочего.",
      client:
        "«Платежка» - красноярская финансовая организация, занимающаяся деятельностью по приему платежей физических лиц платежными агентами.",
      task: `Длительное время клиент принимал платежи через терминалы. Для охвата большей целевой аудитории появилась надобность в мобильном приложении. \nНеобходимо было сделать клиентскую часть сервиса для приема платежей через эквайринг.`,
      technologies: [
        {
          title: "Swift",
          image: Swift,
          imageWeb: SwiftWeb,
        },
        {
          title: "Kotlin",
          image: Kotlin,
          imageWeb: KotlinWeb,
        },
      ],
      result:
        "Сервис позволяет пользователям оплачивать услуги ЖКХ, мобильную связь, интернет и многое другое. Реализован безопасный эквайринг.",
      app: {
        image: "",
        appStore: "",
        playMarket: "",
        appGallery: "",
        RuStore: "",
      },
    },
  },
//   {
//     url: "LionInvest",
//     short: {
//       description: "Инвестиционное приложение “LionInvest”",
//       image: LionInvest__TitleImage,
//       imageWeb: LionInvest__TitleImageWeb,
//     },
//     long: {
//       title: `Инвестиционное приложение\n«ЛионИнвест»`,
//       image: LionInvest__Image1,
//       imageWeb: LionInvest__Image1Web,
//       about: "Мобильное приложение для инвесторов.",
//       client:
//         "«LionInvest» - европейская финансовая организация, занимающаяся инвестированием в санитарное оборудование и недвижимость, а также предоставляющая иные финансовые услуги.",
//       task: `Длительное время учет вложенных средств велся в примитивных инструментах.\nС притоком огромного числа инвесторов появилась идея о создании системы контроля документооборота и движения денежных средств.`,
//       technologies: [
//         {
//           title: "Swift",
//           image: Swift,
//           imageWeb: SwiftWeb,
//         },
//         {
//           title: "Kotlin",
//           image: Kotlin,
//           imageWeb: KotlinWeb,
//         },
//         {
//           title: "GoLang",
//           image: GoLang,
//           imageWeb: GoLangWeb,
//         },
//         {
//           title: "React",
//           image: React,
//           imageWeb: ReactWeb,
//         },
//       ],
//       result:
//         "Благодаря приложению теперь инвесторы могут в любой момент повлиять на свой депозит, получить договор с компанией «LionInvest» в электронном формате, а также совершать P2P операции.",
//       app: {
//         image: LionInvest__Image2,
//         imageWeb: LionInvest__Image2Web,
//         appStore: "https://apps.apple.com/us/app/lioninvest/id1600873576",
//         playMarket:
//           "https://play.google.com/store/apps/details?id=ru.haferflocken.lion_invest_android",
//         // appGallery:""
//       },
//     },
//   },
  {
    url: "DSoft",
    short: {
      description: "Мобильное приложение исполнителя “D-Soft”",
      image: DSoft__TitleImage,
      imageWeb: DSoft__TitleImageWeb,
    },
    long: {
      title: `Приложение исполнителя\nдля компании “D-Soft”`,
      image: DSoft__Image1,
      imageWeb: DSoft__Image1Web,
      about: "Мобильное приложение для исполнителя.",
      client:
        "“D-Soft” – компания, занимающаяся внедрением программного комплекса ДС ЖКХ в управляющие компании.",
      task: `Система компании D-Soft ведет учет всех выполненных работ и затрат по каждому дому. \nЧтобы упростить ведение учета необходимо было разработать мобильное приложение, в котором исполнитель будет иметь возможность работать с заявками жителей домов.`,
      technologies: [
        {
          title: "Swift",
          image: Swift,
          imageWeb: SwiftWeb,
        },
        {
          title: "Kotlin",
          image: Kotlin,
          imageWeb: KotlinWeb,
        },
        {
          title: "GoLang",
          image: GoLang,
          imageWeb: GoLangWeb,
        },
      ],
      result:
        "Приложение позволяет исполнителю откликаться на заявки жителей, прикреплять файлы и отчитываться перед диспетчерской службой по проделанной работе.",
      app: {
        image: "",
        appStore: "",
        playMarket: "",
        appGallery: "",
        RuStore: "",
      },
    },
  },
]);
export const setProjectsLists = createEvent<any>();
$ProjectsLists.on(setProjectsLists, (_, val) => val);
