import "../styles/WeOffer.css";
import IconWeApp from "../../../common/assets/MainPage/WeOffer/IconWeApp.png";
import Union from "../../../common/assets/MainPage/WeOffer/Union.svg";

export const WeOffer = () => {
  return (
    <div className="WeOffer" id="referense">
      <div className="WeOffer__Title">
        <span>Мы предлагаем</span>
      </div>
      <div className="WeOffer__TextAndFormAndImage">
        <div className="WeOffer__TextAndForm">
          <span className="WeOffer__Text">
            <div className="WeOffer_UnionAndText">
              <img className="WeOffer_UnionAndText_Union" src={Union} alt="" />
              <span>
                Нативная разработка мобильных приложений для iOs и Android и
                сайтов под различные устройства
              </span>
            </div>
          </span>
          <span className="WeOffer__Text">
            <div className="WeOffer_UnionAndText">
              <img className="WeOffer_UnionAndText_Union" src={Union} alt="" />
              <span>
                Согласование требований, подготовка качественного и стильного
                дизайна, программирование и тестирование функционала
              </span>
            </div>
          </span>
          <span className="WeOffer__Text">
            <div className="WeOffer_UnionAndText">
              <img className="WeOffer_UnionAndText_Union" src={Union} alt="" />
              <span>
                Публикация приложения в маркетплейсах и настройка сервера и
                домена
              </span>
            </div>
          </span>
          <span className="WeOffer__Text">
            <div className="WeOffer_UnionAndText">
              <img className="WeOffer_UnionAndText_Union" src={Union} alt="" />
              <span>Поддержка приложения и выпуск обновлений</span>
            </div>
          </span>
          <span className="WeOffer__Text">
            <div className="WeOffer_UnionAndText">
              <img className="WeOffer_UnionAndText_Union" src={Union} alt="" />
              <span>Разработка мобильного приложения с интеграцией с 1С</span>
            </div>
          </span>
        </div>
        <div className="WeOffer__Form">
          <img className="WeOffer_image" src={IconWeApp} alt="" />
        </div>
      </div>
    </div>
  );
};
