import { createEvent, restore } from "effector";
import Technology1 from '../../../common/assets/MainPage/TechList/Навык 1.png'
import Technology2 from '../../../common/assets/MainPage/TechList/Навык 2.png'
import Technology3 from '../../../common/assets/MainPage/TechList/Навык 3.png'
import Technology4 from '../../../common/assets/MainPage/TechList/Навык 4.png'
import Technology5 from '../../../common/assets/MainPage/TechList/Навык 5.png'
import Technology6 from '../../../common/assets/MainPage/TechList/Навык 6.png'
import Technology7 from '../../../common/assets/MainPage/TechList/Навык 7.png'
import Technology8 from '../../../common/assets/MainPage/TechList/Навык 8.png'

import Technology1Web from '../../../common/assets/MainPage/TechList/Навык 1.webp'
import Technology2Web from '../../../common/assets/MainPage/TechList/Навык 2.webp'
import Technology3Web from '../../../common/assets/MainPage/TechList/Навык 3.webp'
import Technology4Web from '../../../common/assets/MainPage/TechList/Навык 4.webp'
import Technology5Web from '../../../common/assets/MainPage/TechList/Навык 5.webp'
import Technology6Web from '../../../common/assets/MainPage/TechList/Навык 6.webp'
import Technology7Web from '../../../common/assets/MainPage/TechList/Навык 7.webp'
import Technology8Web from '../../../common/assets/MainPage/TechList/Навык 8.webp'

export interface IUsingTechnologyItem {
  icon: string
  iconWeb:string
  title: string
}
export const setTechnologyList = createEvent<IUsingTechnologyItem[]>();
export const $technologyList = restore<IUsingTechnologyItem[]>(
  setTechnologyList,
  [{ icon: Technology1,iconWeb:Technology1Web, title: "GO" },
  { icon: Technology2,iconWeb:Technology2Web, title: "REACT" },
  { icon: Technology3,iconWeb:Technology3Web, title: "SWIFT" },
  { icon: Technology4,iconWeb:Technology4Web, title: "KOTLIN" },
  // { icon: Technology5, title: "GO" },
  // { icon: Technology6, title: "GO" },
  { icon: Technology7,iconWeb:Technology7Web, title: "FIGMA" },
  { icon: Technology8,iconWeb:Technology8Web, title: "JS" }]
);